import React, { useState, useEffect } from "react";
import CaseCard from "./CaseCard";
import styled from "styled-components";
import { color } from "../templates/ui";
import Container from "../atoms/Container";
import dynamic from "next/dynamic";

const TinySlider = dynamic(() => import("../packages/tiny-slider-react"), {
  ssr: false,
});

const StyledContainer = styled(Container)`
  position: relative;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-right: 0;

  @media only screen and (min-width: 768px) {
    padding-right: 20px;
  }

  .tns-outer {
    position: relative;
    margin-right: 0 !important;
    padding: 40px 0 !important;
    margin-left: -30px !important;
    padding-left: 15px !important;
    z-index: 1;
    overflow: hidden;

    @media only screen and (min-width: 768px) {
      padding-left: 40px !important;
    }

    @media only screen and (min-width: 1200px) {
      margin-right: -100% !important;
      margin-left: -75px !important;
      padding-left: 75px !important;
    }
  }

  .tns-ovh {
    overflow: visible;
  }

  @media only screen and (min-width: 1200px) {
    .tns-slider {
      .tns-item {
        transition: transform 0.2s ease-in-out;
      }
      > .tns-slide-active ~ .tns-slide-active {
        transform: scale(1);

        .case-card {
          padding-top: 80px;

          p {
            opacity: 0;
            max-height: 0;
            margin: 0;
          }
        }
      }
      .tns-slide-active {
        transform: scale(1.1);

        .case-card {
          padding-top: 25px;

          p {
            opacity: 1;
            max-height: none;
            margin: 16px 0;
          }
        }
      }
    }
  }

  .tns-controls {
    position: absolute;
    bottom: 0;
    right: 0;

    @media only screen and (min-width: 1200px) {
      bottom: 25px;
      left: 636px;
      right: auto;
    }

    button {
      position: relative;
      background-color: ${color.primary.base};
      transition: background 0.2s ease-in-out;
      text-align: center;
      border: none;
      width: 34px;
      height: 34px;
      padding: 0;
      color: transparent;
      cursor: pointer;
      z-index: 1;

      &:focus {
        outline: none;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.6;
      }

      &:hover {
        background-color: ${color.primary.hover};
      }

      &:first-child {
        margin-right: 5px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMTEgMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxkZWZzPiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSI2MzguMTA4MjM4IDM4OC44MDU3NjIgNjQ2LjAyMjIzOCAzODAuODkxNzYyIDY0OC4wODkxNzUgMzgyLjk1ODY5OSA2NDIuMjQyMTEyIDM4OC44MDU3NjIgNjQ4LjA4OTE3NSAzOTQuNjUyODI1IDY0Ni4wMjIyMzggMzk2LjcxOTc2MiI+PC9wb2x5Z29uPiAgICAgICAgPGZpbHRlciB4PSItNS4wJSIgeT0iLTMuMiUiIHdpZHRoPSIxMTAuMCUiIGhlaWdodD0iMTEyLjYlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIGlkPSJmaWx0ZXItMiI+ICAgICAgICAgICAgPGZlT2Zmc2V0IGR4PSIwIiBkeT0iMSIgaW49IlNvdXJjZUFscGhhIiByZXN1bHQ9InNoYWRvd09mZnNldE91dGVyMSI+PC9mZU9mZnNldD4gICAgICAgICAgICA8ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgMCAwIDAgMC4xNSAwIiB0eXBlPSJtYXRyaXgiIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiPjwvZmVDb2xvck1hdHJpeD4gICAgICAgIDwvZmlsdGVyPiAgICA8L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkhvbWVwYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjc4LjAwMDAwMCwgLTE1MjMuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgICAgICA8ZyBpZD0iQ2FzZXMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwLjAwMDAwMCwgMTE0My4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9IlBhdGgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY0My4wOTg3MDYsIDM4OC44MDU3NjIpIHJvdGF0ZSgtMi4wMDAwMDApIHRyYW5zbGF0ZSgtNjQzLjA5ODcwNiwgLTM4OC44MDU3NjIpICI+ICAgICAgICAgICAgICAgICAgICA8dXNlIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjEiIGZpbHRlcj0idXJsKCNmaWx0ZXItMikiIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPiAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsPSIjRkZGRkZGIiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==) !important;
          background-repeat: no-repeat;
          width: 10px;
          height: 17px;
        }
      }

      &:last-child {
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTEgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxkZWZzPiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSI2ODYuMDk4NzA2IDM5My43OTYyMzEgNjc4LjE4NDcwNiAzODUuODgyMjMxIDY4MC4yNTE2NDMgMzgzLjgxNTI5NCA2ODYuMDk4NzA2IDM4OS42NjIzNTcgNjkxLjk0NTc2OSAzODMuODE1Mjk0IDY5NC4wMTI3MDYgMzg1Ljg4MjIzMSI+PC9wb2x5Z29uPiAgICAgICAgPGZpbHRlciB4PSItMy4yJSIgeT0iLTUuMCUiIHdpZHRoPSIxMDYuMyUiIGhlaWdodD0iMTIwLjAlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIGlkPSJmaWx0ZXItMiI+ICAgICAgICAgICAgPGZlT2Zmc2V0IGR4PSIwIiBkeT0iMSIgaW49IlNvdXJjZUFscGhhIiByZXN1bHQ9InNoYWRvd09mZnNldE91dGVyMSI+PC9mZU9mZnNldD4gICAgICAgICAgICA8ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgMCAwIDAgMC4xNSAwIiB0eXBlPSJtYXRyaXgiIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiPjwvZmVDb2xvck1hdHJpeD4gICAgICAgIDwvZmlsdGVyPiAgICA8L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkhvbWVwYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzIxLjAwMDAwMCwgLTE1MjMuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgICAgICA8ZyBpZD0iQ2FzZXMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwLjAwMDAwMCwgMTE0My4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9IlBhdGgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY4Ni4wOTg3MDYsIDM4OC44MDU3NjIpIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTY4Ni4wOTg3MDYsIC0zODguODA1NzYyKSAiPiAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIxIiBmaWx0ZXI9InVybCgjZmlsdGVyLTIpIiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4gICAgICAgICAgICAgICAgICAgIDx1c2UgZmlsbD0iI0ZGRkZGRiIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=) !important;
          background-repeat: no-repeat;
          width: 10px;
          height: 17px;
        }
      }
    }
  }
`;

const CardOuterWrapper = styled.div``;

const StyledCaseCard = styled(CaseCard)`
  max-width: 467px;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    margin: 0;
  }
`;

function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

const settings = {
  lazyload: true,
  nav: false,
  mouseDrag: false,
  loop: true,
  items: 1,
  gutter: 20,
  responsive: {
    768: {
      items: 1.5,
      gutter: 50,
    },
    1200: {
      items: 4,
      gutter: 5,
    },
  },
};

const CaseSlider = ({ posts }) => {
  const [shuffledPosts, setShuffledPosts] = useState([]);
  useEffect(() => {
    setShuffledPosts(shuffleArray(posts));
  }, [posts]);

  return (
    <StyledContainer>
      <TinySlider settings={settings}>
        {shuffledPosts.map((post) => (
          <CardOuterWrapper key={post.href}>
            <StyledCaseCard
              titleLineFirst={post.titleLineFirst}
              titleLineSecond={post.titleLineSecond}
              description={post.description}
              logo={post.logo}
              image={post.image}
              href={post.href}
            />
          </CardOuterWrapper>
        ))}
      </TinySlider>
    </StyledContainer>
  );
};

export default CaseSlider;
