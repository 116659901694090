import React, { Component } from "react";
import styled from "styled-components";
import Container from "../atoms/Container";
import Title from "../atoms/Title";
import GrayBgArtwork from "../atoms/GrayBgArtwork";
import Knhb from "../atoms/logos/Knhb";
import Schotpoort from "../atoms/logos/Schotpoort";
import Cityside from "../atoms/logos/Cityside";
import PayrollSelect from "../atoms/logos/PayrollSelect";
import Benu from "../atoms/logos/Benu";
import Superhost from "../atoms/logos/Superhost";
import CaseSlider from "../molecules/CaseSlider";
import Timing from "../atoms/logos/Timing";
import Smulweb from "../atoms/logos/Smulweb";

const StyledContainer = styled(Container)`
  position: relative;

  @media only screen and (min-width: 768px) {
    padding-bottom: 100px;
  }
`;

const StyledGrayBgArtwork = styled(GrayBgArtwork)`
  display: none;
  width: 480px;
  height: 190px;
  bottom: 90px;
  left: -40px;

  @media only screen and (min-width: 1100px) {
    display: block;
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StyledKnhb = styled(Knhb)`
  width: 84px;
  height: 18px;

  @media only screen and (min-width: 576px) {
    width: 120px;
    height: 26px;
  }
`;

const StyledSchotpoort = styled(Schotpoort)`
  width: 120px;
  height: auto;
`;

const StyledCityside = styled(Cityside)`
  width: 40px;
  height: auto;

  @media only screen and (min-width: 576px) {
    width: 50px;
    height: auto;
  }
`;

const StyledPayrollSelect = styled(PayrollSelect)`
  width: 120px;
  height: auto;
`;

const StyledBenu = styled(Benu)`
  width: 66px;
  height: 25px;

  @media only screen and (min-width: 576px) {
    width: 120px;
    height: 26px;
  }
`;

const StyledSuperhost = styled(Superhost)`
  width: 80px;
  height: auto;

  @media only screen and (min-width: 576px) {
    width: 120px;
    height: 26px;
  }
`;

const StyledTiming = styled(Timing)`
  width: 80px;
  height: auto;

  @media only screen and (min-width: 576px) {
    width: 120px;
    height: 26px;
  }
`;

const StyledSmulweb = styled(Smulweb)`
  width: 80px;
  height: auto;

  @media only screen and (min-width: 576px) {
    width: 120px;
    height: 26px;
  }
`;

const posts = [
  {
    titleLineFirst: "Alle ballen in de lucht",
    titleLineSecond: "voor de KNHB",
    description:
      "200.000 gebruikers en 8000 wedstrijden per week waarvan alle data in goede banen geleid wordt door software die werkt zoals de KNHB wil.",
    logo: <StyledKnhb />,
    image: require("../assets/img/knhb/knhb-header.jpg"),
    href: "/cases/knhb",
  },
  {
    titleLineFirst: "Benu Direct maakt de",
    titleLineSecond: "overstap naar self-service",
    description:
      "50.000 telefoontjes bespaard, want klanten regelen nu zelfstandig hun dagelijkse zaken op het online klantenportaal.",
    logo: <StyledBenu />,
    image: require("../assets/img/benu/Benu_Voortraject_Header.jpg"),
    href: "/cases/benu-voortraject",
  },
  {
    titleLineFirst: "Solide webapplicatie die",
    titleLineSecond: "staat als een huis",
    description:
      "1 druk op de knop om de facturatie voor meer dan 1000 verhuureenheden versturen en de betalingen te registreren.",
    logo: <StyledCityside />,
    image: require("../assets/img/cityside/cityside-header.jpg"),
    href: "/cases/cityside",
  },
  {
    titleLineFirst: "Één order portaal dat alles",
    titleLineSecond: "bezorgt, behalve stress",
    description:
      "Alle klanten van Schotpoort krijgen hun eigen, op maat gemaakte business logica voorgeschoteld om opdrachten snel en digitaal door te geven.",
    logo: <StyledSchotpoort />,
    image: require("../assets/img/schotpoort/schotpoort-header.jpg"),
    href: "/cases/schotpoort",
  },
  {
    titleLineFirst: "Nieuw personeel aannemen",
    titleLineSecond: "eenvoudig met de app",
    description:
      "Contractverlenging van o.a. Nuon en Picnic door een super gebruiksvriendelijk en gepersonaliseerd dashboard.",
    logo: <StyledPayrollSelect />,
    image: require("../assets/img/payroll/payroll-header.png"),
    href: "/cases/payroll",
  },
  {
    titleLineFirst: "Technologie combineren",
    titleLineSecond: "om een Superhost te worden",
    description:
      "Een stijgend aantal terugkerende klanten, omdat door datagedreven gastheerschap de klanttevredenheid verhoogd is.",
    logo: <StyledSuperhost />,
    image: require("../assets/img/bungalowpark/superhost-header.jpg"),
    href: "/cases/superhost",
  },
  {
    titleLineFirst: "Behapbare migratie van",
    titleLineSecond: "online platform",
    description:
      "20 jaar oud platform is nu stabiel, veilig, schaalbaar en toekomstbestendig voor nog eens 20 jaar.",
    logo: <StyledSmulweb />,
    image: require("../assets/img/smulweb/migratie/Smulweb_header.png"),
    href: "/cases/smulweb-migratie",
  },
  {
    titleLineFirst: "EEN PLATFORM DAT",
    titleLineSecond: "VERTROUWEN GEEFT",
    description:
      "Applicatie die eenvoudig beheert, uitgebreidt en getest wordt door BENU Direct.",
    logo: <StyledBenu />,
    image: require("../assets/img/benu/Benu_Ontwikkeling_Header.jpg"),
    href: "/cases/benu-ontwikkeling",
  },
  {
    titleLineFirst: "Digitale applicatie creëert",
    titleLineSecond: "waardevolle tijd",
    description:
      "Maximale aandacht voor de klant door het digitaliseren van een tijdsintensief Excel document.",
    logo: <StyledTiming />,
    image: require("../assets/img/timing/Timing_header.png"),
    href: "/cases/timing",
  },
  {
    titleLineFirst: "Automatisch schalende",
    titleLineSecond: "applicatie voor Smulweb",
    description:
      "Verzekerd van goede bereikbaarheid door automatisch op en af te schalen.",
    logo: <StyledSmulweb />,
    image: require("../assets/img/smulweb/infrastructure/Smulweb_AWS_header.png"),
    href: "/cases/smulweb-infrastructuur",
  },
  {
    titleLineFirst: "Slim gebruikmaken van",
    titleLineSecond: "clouddiensten",
    description:
      "Razendsnel checken waar jouw foto onrechtmatig gebruikt wordt met behulp van Amazon Web Services én Google Vision AI.",
    image: require("../assets/img/online-copyright-checker/CopyrightChecker_Header.png"),
    href: "/cases/online-copyright-checker",
  },
];

class CasesSlider extends Component {
  render() {
    return (
      <StyledContainer>
        <Title color="black" tag="h4" hasStripe={true} caps={true}>
          Cases
        </Title>
        <SliderWrapper>
          <CaseSlider posts={posts} />
        </SliderWrapper>
        <StyledGrayBgArtwork />
      </StyledContainer>
    );
  }
}

export default CasesSlider;
