import React from "react";
import styled from "styled-components";
import ServiceTeaserIntro from "../molecules/ServiceTeaserIntro";
import ServiceTeaserTile from "../molecules/ServiceTeaserTile";
import LightBulb from "../atoms/icons/LightBulb";
import People from "../atoms/icons/People";
import BuildTools from "../atoms/icons/BuildTools";
import Title from "../atoms/Title";
import Container from "../atoms/Container";

const StyledServicesTeaserBar = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

const StyledLightBulb = styled(LightBulb)`
  width: 64px;
  height: 70px;
`;

const StyledPeople = styled(People)`
  width: 90px;
  height: 60px;
`;

const StyledBuildTools = styled(BuildTools)`
  width: 70px;
  height: 60px;
`;

const StyledTeaserIntroWrapper = styled.div``;

const StyledTeaserTileWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: 540px) {
    flex-direction: row;
  }

  > a {
    &:before {
      transition: background-color 0.2s ease-in-out;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &:nth-child(1) {
      &:before {
        background-color: rgba(10, 11, 9, 0.9);
      }
    }

    &:nth-child(2) {
      &:before {
        background-color: rgba(24, 24, 24, 0.9);
      }
    }

    &:nth-child(3) {
      &:before {
        background-color: rgba(37, 37, 37, 0.9);
      }
    }
  }
`;

const TitleWrapper = styled.div`
  padding: 40px 0;
`;

const Section = styled.section`
  padding-bottom: 40px;

  @media only screen and (min-width: 768px) {
    padding-bottom: 100px;
  }
`;

const ServicesTeaserBar = ({ className }) => (
  <Section>
    <Container>
      <TitleWrapper>
        <Title tag="h4" caps={true} hasStripe={true}>
          Diensten
        </Title>
      </TitleWrapper>
    </Container>
    <StyledServicesTeaserBar className={className}>
      <StyledTeaserIntroWrapper>
        <ServiceTeaserIntro
          title="Digitaal versnellen, hoe pak je dat aan en waar begin je?"
          description="Met scherpe analyses, slimme oplossingen en innovatieve toepassingen maken wij nieuwe technologieën praktisch inzetbaar."
        />
      </StyledTeaserIntroWrapper>
      <StyledTeaserTileWrapper>
        <ServiceTeaserTile
          image={require("../assets/img/content/diensten-denktank.jpg")}
          icon={<StyledLightBulb />}
          title="Digitale denktank"
          moreText="Lees meer"
          href="/diensten"
        />
        <ServiceTeaserTile
          image={require("../assets/img/content/diensten-devteams.jpg")}
          icon={<StyledPeople />}
          title="Development teams"
          moreText="Lees meer"
          href="/diensten"
        />
        <ServiceTeaserTile
          image={require("../assets/img/content/diensten-support.jpg")}
          icon={<StyledBuildTools />}
          title="Beheer en support"
          moreText="Lees meer"
          href="/diensten"
        />
      </StyledTeaserTileWrapper>
    </StyledServicesTeaserBar>
  </Section>
);

export default ServicesTeaserBar;
