import dynamic from "next/dynamic";
import React, { Component } from "react";
import styled from "styled-components";
import Container from "../atoms/Container";
import GrayBgArtwork from "../atoms/GrayBgArtwork";
import Title from "../atoms/Title";
import CultureCard from "../molecules/CultureCard";
import { color } from "../templates/ui";

const TinySlider = dynamic(() => import("../packages/tiny-slider-react"), {
  ssr: false,
});

const StyledContainer = styled(Container)`
  position: relative;
  padding-bottom: 40px;

  .tns-outer {
    position: relative;
    margin-right: 0 !important;
    padding: 40px 0 !important;
    margin-left: -25px !important;
    padding-left: 25px !important;
    z-index: 1;

    @media only screen and (min-width: 768px) {
      margin-right: -100% !important;
    }
  }

  .tns-controls {
    position: absolute;
    bottom: 0;
    right: 0;

    @media only screen and (min-width: 768px) {
      left: 25px;
      right: auto;
    }

    @media only screen and (min-width: 1410px) {
      right: 1045px;
      bottom: 50px;
      left: auto;
    }

    button {
      position: relative;
      background-color: ${color.primary.base};
      transition: background 0.2s ease-in-out;
      text-align: center;
      border: none;
      width: 34px;
      height: 34px;
      padding: 0;
      color: transparent;
      cursor: pointer;
      z-index: 1;

      &:focus {
        outline: none;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.6;
      }

      &:hover {
        background-color: ${color.primary.hover};
      }

      &:first-child {
        margin-right: 5px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMTEgMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxkZWZzPiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSI2MzguMTA4MjM4IDM4OC44MDU3NjIgNjQ2LjAyMjIzOCAzODAuODkxNzYyIDY0OC4wODkxNzUgMzgyLjk1ODY5OSA2NDIuMjQyMTEyIDM4OC44MDU3NjIgNjQ4LjA4OTE3NSAzOTQuNjUyODI1IDY0Ni4wMjIyMzggMzk2LjcxOTc2MiI+PC9wb2x5Z29uPiAgICAgICAgPGZpbHRlciB4PSItNS4wJSIgeT0iLTMuMiUiIHdpZHRoPSIxMTAuMCUiIGhlaWdodD0iMTEyLjYlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIGlkPSJmaWx0ZXItMiI+ICAgICAgICAgICAgPGZlT2Zmc2V0IGR4PSIwIiBkeT0iMSIgaW49IlNvdXJjZUFscGhhIiByZXN1bHQ9InNoYWRvd09mZnNldE91dGVyMSI+PC9mZU9mZnNldD4gICAgICAgICAgICA8ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgMCAwIDAgMC4xNSAwIiB0eXBlPSJtYXRyaXgiIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiPjwvZmVDb2xvck1hdHJpeD4gICAgICAgIDwvZmlsdGVyPiAgICA8L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkhvbWVwYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjc4LjAwMDAwMCwgLTE1MjMuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgICAgICA8ZyBpZD0iQ2FzZXMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwLjAwMDAwMCwgMTE0My4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9IlBhdGgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY0My4wOTg3MDYsIDM4OC44MDU3NjIpIHJvdGF0ZSgtMi4wMDAwMDApIHRyYW5zbGF0ZSgtNjQzLjA5ODcwNiwgLTM4OC44MDU3NjIpICI+ICAgICAgICAgICAgICAgICAgICA8dXNlIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjEiIGZpbHRlcj0idXJsKCNmaWx0ZXItMikiIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPiAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsPSIjRkZGRkZGIiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==) !important;
          background-repeat: no-repeat;
          width: 10px;
          height: 17px;
        }
      }

      &:last-child {
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTEgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxkZWZzPiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSI2ODYuMDk4NzA2IDM5My43OTYyMzEgNjc4LjE4NDcwNiAzODUuODgyMjMxIDY4MC4yNTE2NDMgMzgzLjgxNTI5NCA2ODYuMDk4NzA2IDM4OS42NjIzNTcgNjkxLjk0NTc2OSAzODMuODE1Mjk0IDY5NC4wMTI3MDYgMzg1Ljg4MjIzMSI+PC9wb2x5Z29uPiAgICAgICAgPGZpbHRlciB4PSItMy4yJSIgeT0iLTUuMCUiIHdpZHRoPSIxMDYuMyUiIGhlaWdodD0iMTIwLjAlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIGlkPSJmaWx0ZXItMiI+ICAgICAgICAgICAgPGZlT2Zmc2V0IGR4PSIwIiBkeT0iMSIgaW49IlNvdXJjZUFscGhhIiByZXN1bHQ9InNoYWRvd09mZnNldE91dGVyMSI+PC9mZU9mZnNldD4gICAgICAgICAgICA8ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgMCAwIDAgMC4xNSAwIiB0eXBlPSJtYXRyaXgiIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiPjwvZmVDb2xvck1hdHJpeD4gICAgICAgIDwvZmlsdGVyPiAgICA8L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkhvbWVwYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzIxLjAwMDAwMCwgLTE1MjMuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgICAgICA8ZyBpZD0iQ2FzZXMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwLjAwMDAwMCwgMTE0My4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9IlBhdGgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY4Ni4wOTg3MDYsIDM4OC44MDU3NjIpIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTY4Ni4wOTg3MDYsIC0zODguODA1NzYyKSAiPiAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIxIiBmaWx0ZXI9InVybCgjZmlsdGVyLTIpIiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4gICAgICAgICAgICAgICAgICAgIDx1c2UgZmlsbD0iI0ZGRkZGRiIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=) !important;
          background-repeat: no-repeat;
          width: 10px;
          height: 17px;
        }
      }
    }
  }

  .tns-slider {
    > .tns-slide-active ~ .tns-slide-active {
      .row {
        flex-direction: row-reverse;
        transform: scale(0.7);
        margin-left: -23.5%;

        h4,
        p {
          opacity: 0;
        }
      }
    }
    .tns-slide-active {
      .row {
        flex-direction: row;
        transform: scale(1);

        h4,
        p {
          opacity: 1;
        }
      }
    }
  }
`;

const StyledGrayBgArtwork = styled(GrayBgArtwork)`
  width: 305px;
  height: 365px;
  top: 0;
  bottom: auto;
  left: 550px;
`;

const settings = {
  lazyload: true,
  nav: false,
  mouseDrag: false,
  loop: true,
  items: 1,
  gutter: 5,
  responsive: {
    768: {
      items: 2,
    },
  },
};

class CultureSlider extends Component {
  render() {
    return (
      <StyledContainer>
        <Title color="black" tag="h4" hasStripe={true} caps={true}>
          Cultuur
        </Title>
        <TinySlider settings={settings}>
          <CultureCard
            title="Meetups, ode aan de code"
            description="Kennis bijspijkeren, goed eten, lekkere biertjes en interessante talks. Dat is onze Ode aan de Code in het kort. Ongeveer 6 keer per jaar nodigen we sprekers uit om hun kennis te delen over de nieuwe ontwikkelingen, technieken en tools die ons helpen ons werk nog beter te doen. #bijzijnismeemaken"
            videoSrc="https://player.vimeo.com/video/343211526?autoplay=0&color=DA303A&title=0&byline=0&portrait=0&dnt=1"
            hrefExternal="https://www.meetup.com/nl-NL/dijdigital/"
          />
          <CultureCard
            title="Kartevent"
            description="Met 12 DIJ coureurs scheurden we naar de veertiende plek tijdens Apeldoorn City Karting. Na de nodige voorbereidingen, trainingsuren en tactiekbesprekingen zijn we hartstikke trots op het eindresultaat. #volgendjaarweer"
            videoSrc="https://player.vimeo.com/video/343210885?autoplay=0&color=DA303A&title=0&byline=0&portrait=0&dnt=1"
          />
          <CultureCard
            title="BBQ"
            description="We luiden de zomer in met een heerlijke BBQ en uiteraard de nodige biertjes en frisjes. De zon heeft de uitnodiging gemist, maar dat mag de pret niet drukken. #morgenietsminderfitdanvandaag"
            videoSrc="https://player.vimeo.com/video/343211226?autoplay=0&color=DA303A&title=0&byline=0&portrait=0&dnt=1"
          />
          <CultureCard
            title="Hackathon"
            description="Creatieve ideeën en innovatieve plannen vlogen in het rond: de eerste echte DIJ Hackathon is een feit. Ideeën werden gepitcht, teams gevormd, plannen ontwikkeld en na een dag hard werken werd al het moois gepresenteerd. Team Office Automation kwam als winnaar uit de stemronde. Zij hebben binnen één dag ervoor gezorgd dat met 1 druk op de knop alle verlichting aan gaat wanneer je het pand binnen komt."
            videoSrc="https://player.vimeo.com/video/343211814?autoplay=0&color=DA303A&title=0&byline=0&portrait=0&dnt=1"
          />
        </TinySlider>
        <StyledGrayBgArtwork />
      </StyledContainer>
    );
  }
}

export default CultureSlider;
