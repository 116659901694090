import React, { Component } from "react";
import TopBar from "../organisms/TopBar";
import Header from "../organisms/Header";
import Footer from "../organisms/Footer";
import Page from "../atoms/Page";
import Newsletter from "../organisms/Newsletter";
import CultureSlider from "../organisms/CultureSlider";
import IntroTextWithOneLiner from "../organisms/IntroTextWithOneLiner";
import Container from "../atoms/Container";
import ServicesTeaserBar from "../organisms/ServicesTeaserBar";
import CallToAction from "../molecules/CallToAction";
import styled from "styled-components";
import Stripe from "../atoms/icons/Stripe";
import { animateScroll as scroll } from "react-scroll/modules";
import BlogTeasers from "../organisms/BlogTeasers";
import CasesSlider from "../organisms/CasesSlider";
import Head from "next/head";
import dynamic from "next/dynamic";
import Certfications from '../molecules/Certifications';
import AwsCTA from '../molecules/AwsCTA';
const Instafeed = dynamic(() => import("../molecules/Instafeed"), {
  ssr: false,
});

const StyledCallToActionWrapper = styled.section`
  padding: 0;

  @media only screen and (min-width: 768px) {
    padding: 70px 0;
  }
`;

const StyledContainer = styled(Container)`
  padding-bottom: 40px;
  padding-top: 10px;

  @media only screen and (min-width: 768px) {
    padding-top: 30px;
    padding-bottom: 100px;
  }
`;

const PreLoader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  z-index: 100;
  transition: opacity 0.6s ease-in-out;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledStripe = styled(Stripe)`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(720deg);
    }
  }

  @keyframes grow {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(80);
    }
  }

  width: 150px;
  height: auto;
  animation: spin 1s 1, grow 0.5s 1s 1;
  animation-fill-mode: forwards;
`;

class Index extends Component {
  state = {
    opacity: 1,
    display: "block",
    progress: 0,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ opacity: 0 });
    }, 1500);
    setTimeout(() => {
      this.setState({ display: "none", progress: 1 });
    }, 2000);

    setTimeout(() => {
      this.setState({ display: "none", progress: 2 });
    }, 2500);

    scroll.scrollTo(0, {
      duration: 100,
      delay: 0,
    });
  }

  render() {
    return (
      <>
        <Head>
          <title>DIJ - Dedicated Digital Development Teams</title>
        </Head>
        <PreLoader
          style={{
            opacity: this.state.opacity,
            display: this.state.display,
          }}
        >
          <IconWrapper>
            <StyledStripe />
          </IconWrapper>
        </PreLoader>
        <Page>
          <TopBar />
          <Header
            titleLineFirst="Dedicated Digital"
            titleLineSecond="Development Teams"
            hasStripes={true}
            videoUrl={require("../assets/video/home-header.mp4").default}
            subText="samen met onze teams sluit je jouw onderneming aan op de digitale wereld!"
            buttonText="Is DIJ wat voor jou?"
            href="/digitaal-spreekuur"
            isHomePage={true}
          />
          <StyledContainer>
            <IntroTextWithOneLiner
              sectionTitle="Statement"
              text="Als innoveren in het digitale tijdperk makkelijk zou zijn, had je ons niet nodig. DIJ heeft een voorliefde voor complexe digitale vraagstukken. Onze teams sluiten nieuwe technologieën naadloos aan op jouw onderneming, zodat jij flink kan versnellen in de digitale wereld."
              oneLiner="Morgen beter dan vandaag"
              moreText="Ontdek meer"
            />
          </StyledContainer>
          <Certfications />
          <AwsCTA title="Innovatie gaat sneller in de cloud" preText="Als digitaal bedrijf wil je maximale waarde leveren voor je eindgebruikers. Wij helpen je daarbij met cloud-native development op basis van Amazon Web Services." text="We combineren een build-buy-assemble aanpak met MACH-architectuur en de infrastructuur en services van AWS. Dat maakt onze applicaties slim, snel, effectief en betrouwbaar." buttonText="Ja, ik wil innoveren in de cloud" href="/aws-cloud" />
          <CasesSlider />
          {this.state.progress >= 1 && (
            <>
              <ServicesTeaserBar />
              <CultureSlider />
              <Container>
                <StyledCallToActionWrapper>
                  <div className="row">
                    <CallToAction
                      className="col-md-8 col-md-offset-2"
                      image={require("../assets/img/content/diensten-support.jpg")}
                      title="Meer weten? Neem contact met ons op!"
                      text={
                        <span>
                          Bel ons op{" "}
                          <a href="tel:0557507811">
                            <strong>055 750 7811 </strong>
                          </a>
                          of email ons op{" "}
                          <a href="mailto:info@dij.digital">
                            <strong>info@dij.digital</strong>
                          </a>
                        </span>
                      }
                    />
                  </div>
                </StyledCallToActionWrapper>
              </Container>
              <BlogTeasers />
              <Instafeed />
            </>
          )}
        </Page>
        {this.state.progress >= 2 && (
          <>
            <Newsletter />
            <Footer />
          </>
        )}
      </>
    );
  }
}

export default Index;
