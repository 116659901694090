import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";
import Title from "../atoms/Title";
import ReadMoreButton from "../atoms/ReadMoreButton";
import RouterLink from "../atoms/Link";

const TitleWrapper = styled.div`
  padding: 20px 0 0 60px;

  @media only screen and (min-width: 1240px) {
    padding: 20px 0 0 90px;
  }
`;

const StyledContainerBottom = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0 50px 20px 0;
`;

const StyledIntroTextWithOneLiner = styled.div`
  position: relative;
  background-color: ${color.base.base};
  color: ${color.body.base};
  padding: 20px 0;
  margin-left: -60px;
  z-index: 1;
`;

const StyledIntroTextWrapper = styled.div`
  position: relative;
  padding-left: 60px;

  &:before {
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100% - 22px);
    width: 4px;
    background-color: ${color.primary.base};

    @media only screen and (min-width: 768px) {
      content: "";
    }
  }
`;

const StyledIntroText = styled.p`
  font-size: 16px;
  line-height: 1.8rem;
  padding-right: 40px;

  @media only screen and (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledOneLinerWrapper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (min-width: 768px) {
    padding-left: 50px;
  }
`;

const StyledOneLinerText = styled(Title)`
  font-size: 28px;
  line-height: 2.8rem;
  padding-left: 60px;
  padding-bottom: 40px;

  @media only screen and (min-width: 576px) {
    font-size: 48px;
    line-height: 3.2rem;
  }

  @media only screen and (min-width: 768px) {
    margin-top: -32px;
    padding-left: 0;
    padding-bottom: 0;
  }

  @media only screen and (min-width: 991px) {
    font-size: 58px;
    line-height: 3.8rem;
  }
`;

const IntroTextWithOneLiner = ({
  className,
  sectionTitle,
  text,
  oneLiner,
  moreText,
}) => (
  <StyledIntroTextWithOneLiner>
    {sectionTitle && (
      <TitleWrapper>
        <Title tag="h5" caps={true} hasStripe={true} color="white">
          {sectionTitle}
        </Title>
      </TitleWrapper>
    )}
    <div className="row">
      <StyledIntroTextWrapper className="col-sm-6">
        <StyledIntroText>{text}</StyledIntroText>
        {moreText && (
          <StyledContainerBottom>
            <RouterLink href="/diensten">
              <ReadMoreButton>{moreText}</ReadMoreButton>
            </RouterLink>
          </StyledContainerBottom>
        )}
      </StyledIntroTextWrapper>
      <StyledOneLinerWrapper className="col-sm-6">
        <StyledOneLinerText tag="h2" caps={true} color="white">
          {oneLiner}
        </StyledOneLinerText>
      </StyledOneLinerWrapper>
    </div>
  </StyledIntroTextWithOneLiner>
);

export default IntroTextWithOneLiner;
