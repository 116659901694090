import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import ReadMoreButton from "../atoms/ReadMoreButton";
import Link from "next/link";

const StyledTeaserItem = styled.a`
  position: relative;
  padding: 15px 25px;
  display: flex;
  text-align: center;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  text-decoration: none;

  @media only screen and (min-width: 540px) {
    padding: 35px 25px;
  }

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-top: 12px solid ${color.primary.base};
    z-index: 2;

    @media only screen and (min-width: 1024px) {
      display: none;
    }

    @media only screen and (max-width: 541px) {
      display: none;
    }
  }

  &:hover,
  &:focus {
    img {
      transform: scale(1.15);
    }

    &:before {
      background-color: rgba(190, 36, 45, 0.9) !important;
    }

    .service-icon {
      fill: white;
    }

    .read-more {
      color: white;

      .chevron-r {
        fill: white;
      }
    }
  }
`;

const StyledBgImageWrapper = styled.div`
  transition: all 0.2s ease-in-out;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(1);
  z-index: 0;
`;

const StyledBgImage = styled.img`
  transition: all 0.2s ease-in-out;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transform: scale(1);

  @media only screen and (max-width: 990px) {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }
`;

const StyledIcon = styled.div`
  transition: all 0.2s ease-in-out;
  position: relative;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${color.primary.base};
  z-index: 1;

  @media only screen and (max-width: 540px) {
    margin-bottom: 10px;
  }
`;

const StyledTitle = styled.h3`
  position: relative;
  font-family: ${font.family.head};
  font-weight: 800;
  font-size: 17px;
  text-transform: uppercase;
  color: white;
  z-index: 1;

  @media only screen and (max-width: 540px) {
    margin-bottom: 10px;
  }
`;

const StyledReadMoreButton = styled(ReadMoreButton)`
  transition: color 0.2s ease-in-out;
  position: relative;
  align-self: center;
  z-index: 1;
`;

const ServiceTeaserTile = ({
  className,
  image,
  icon,
  title,
  moreText,
  href,
}) => (
  <Link href={href}>
    <StyledTeaserItem href={href}>
      <StyledBgImageWrapper className="bg-img-wrapper">
        <StyledBgImage src={image} />
      </StyledBgImageWrapper>
      <StyledIcon className="service-icon">{icon}</StyledIcon>
      <StyledTitle>{title}</StyledTitle>
      <StyledReadMoreButton className="read-more">
        {moreText}
      </StyledReadMoreButton>
    </StyledTeaserItem>
  </Link>
);

export default ServiceTeaserTile;
