import React from "react";
import styled from "styled-components";
import Container from '../atoms/Container';
import Laravel from '../atoms/Certifications/Laravel';
import DDA from '../atoms/Certifications/DDA';
import AWS from '../atoms/Certifications/AWS';
import DLF from '../atoms/Certifications/DLF';
import ISO from '../atoms/Certifications/ISO';
import NEN from '../atoms/Certifications/NEN';

const StyledContainer = styled(Container)`
  padding: 0 0 10px 0;
  
  @media only screen and (min-width: 768px) {
    margin-top: -30px;
    padding: 0 0 70px 0;
  }
`;

const StyledItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
  margin-bottom: 30px;
  
  @media only screen and (min-width: 768px) {
    width: 16%;
    margin-bottom: 0;
  }
`;

const StyledLaravel = styled(Laravel)`
  width: 90px;
  height: auto;
  fill: #A3A3A3;
  
  @media only screen and (min-width: 992px) {
      width: 120px;
  }
`;

const StyledDLF = styled(DLF)`
  width: 90px;
  height: auto;
  fill: #A3A3A3;
  
  @media only screen and (min-width: 992px) {
    width: 130px;
  }
`;

const StyledDDA = styled(DDA)`
  width: 70px;
  height: auto;
  fill: #A3A3A3;
  
  @media only screen and (min-width: 992px) {
    width: 90px;
  }
`;

const StyledAWS = styled(AWS)`
  width: 60px;
  height: auto;
  fill: #A3A3A3;
  
  @media only screen and (min-width: 992px) {
    width: 70px;
  }
`;

const StyledIso = styled(ISO)`
  width: 60px;
  height: 60px;

  @media only screen and (min-width: 992px) {
    width: 70px;
    height: 70px;
  }
`;

const StyledNen = styled(NEN)`
  width: 60px;
  height: 60px;
  
  @media only screen and (min-width: 992px) {
    width: 70px;
    height: 70px;
  }
`;


const Certfications = () => (
  <StyledContainer>
    <StyledItems>
      <StyledItem>
        <StyledDLF />
      </StyledItem>
      <StyledItem>
        <StyledLaravel />
      </StyledItem>
      <StyledItem>
        <StyledDDA />
      </StyledItem>
      <StyledItem>
        <StyledAWS />
      </StyledItem>
      <StyledItem>
        <StyledIso />
      </StyledItem>
      <StyledItem>
        <StyledNen />
      </StyledItem>
    </StyledItems>
  </StyledContainer>
);

export default Certfications;
