import React from "react";
import Container from "../atoms/Container";
import styled from "styled-components";
import ReadMoreButton from "../atoms/ReadMoreButton";
import RouterLink from "../atoms/Link";
import Title from "../atoms/Title";
import BlogPosts from "./BlogPosts";

const StyledBlogTeasers = styled.div`
  position: relative;
  padding-bottom: 50px;

  .row {
    position: relative;
  }
`;

const TitleWrapper = styled.div`
  padding: 40px 0;
`;

const StyledContainerBottom = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  z-index: 1;
`;

const StyledContainer = styled(Container)`
  padding-bottom: 20px;
`;

const BlogTeasers = ({ className }) => {
  return (
    <StyledContainer>
      <StyledBlogTeasers className={className}>
        <TitleWrapper>
          <Title tag="h5" caps={true} hasStripe={true}>
            Tech blog
          </Title>
        </TitleWrapper>
        <BlogPosts limit={3} hasArtwork={true} />
        <StyledContainerBottom>
          <RouterLink href="/blogs">
            <ReadMoreButton>Blog overzicht</ReadMoreButton>
          </RouterLink>
        </StyledContainerBottom>
      </StyledBlogTeasers>
    </StyledContainer>
  );
};

export default BlogTeasers;
