import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";

const StyledTeaserIntro = styled.div`
  position: relative;
  background-color: ${color.primary.base};
  padding: 25px;
  z-index: 2;

  @media only screen and (min-width: 1024px) {
    width: 380px;
    min-height: 220px;

    &:before {
      position: absolute;
      content: "";
      right: -11px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid ${color.primary.base};

      @media only screen and (max-width: 540px) {
        bottom: -11px;
        top: auto;
        right: 50%;
        transform: translateX(-50%);
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        border-top: 12px solid ${color.primary.base};
      }
    }
  }

  @media only screen and (max-width: 540px) {
    &:before {
      position: absolute;
      content: "";
      bottom: -11px;
      left: 50%;
      transform: translateX(-50%);
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-top: 12px solid ${color.primary.base};
    }
  }
`;

const StyledTitle = styled.h3`
  text-transform: uppercase;
  font-family: ${font.family.head};
  font-weight: 800;
  color: white;
`;

const StyledDescription = styled.p`
  color: white;
`;

const ServiceTeaserIntro = ({ className, title, description }) => (
  <StyledTeaserIntro>
    <StyledTitle>{title}</StyledTitle>
    <StyledDescription>{description}</StyledDescription>
  </StyledTeaserIntro>
);

export default ServiceTeaserIntro;
