import React from "react";

const Stripe = ({ className }) => (
  <svg
    width="38"
    height="37"
    viewBox="0 0 38 37"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.806 36.986L.849 30.03 30.66.22l6.956 6.955-29.81 29.811"
      fill="#DA303A"
      fillRule="evenodd"
    />
  </svg>
);

export default Stripe;
