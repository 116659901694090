import React from "react";
import styled from "styled-components";
import Title from '../atoms/Title';
import Paragraph from '../atoms/Paragraph';
import Button from '../atoms/Button';
import ChevronRight from '../atoms/icons/ChevronRight';
import AWS from '../atoms/Certifications/AWS';
import AWSCloud from '../atoms/icons/AWSCloud';

const StyledCTA = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #F5F5F5;
  max-width: 790px;
  margin: 0 auto 40px auto;
  
  @media only screen and (min-width: 768px) {
      margin: 0 auto 60px auto;
  }
`;

const _AWSCloud = styled(AWSCloud)`
  display: none;
  position: absolute;
  top: -50px;
  right: -30px;
  width: 181px;
  height: 127px;
  
  @media only screen and (min-width: 768px) {
      display: block;
  }
`;

const StyledChevronRight = styled(ChevronRight)`
  fill: white;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 10px;
  margin-left: 30px !important;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAWS = styled(AWS)`
  display: none;
  width: 60px;
  height: auto;
  fill: #A3A3A3;
  
  @media only screen and (min-width: 768px) {
      display: block;
  }
  
  @media only screen and (min-width: 992px) {
    width: 70px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledAWSCloud = styled(AWSCloud)`
  width: 90px;
  height: 59px;
  margin-right: 15px;
  
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;


const StyledAwsLogo = styled(AWS)`
  width: 60px;
  height: 60px;
  
  @media only screen and (min-width: 768px) {
      display: none;
  }
`;

const CTA = ({ title, text, buttonText, href, preText }) => (
  <StyledCTA>
    <_AWSCloud />
    <StyledTitle tag="h4" hasStripe={true} caps={true}>
      {title}
    </StyledTitle>
    <FlexRow>
      <StyledAWSCloud />
      <StyledAwsLogo />
    </FlexRow>
    <Flex>
      <FirstColumn>
        <Paragraph>
          <strong>{preText}</strong>
        </Paragraph>
        <Paragraph>
          {text}
        </Paragraph>
      </FirstColumn>
      <SecondColumn>
        {buttonText && (
          <a target="_blank" href={href}>
            <Button icon={<StyledChevronRight />}>
              {buttonText}
            </Button>
          </a>
        )}
        <StyledAWS />
      </SecondColumn>
    </Flex>
  </StyledCTA>
);

export default CTA;
